import contextBuilder from '@/utilities/contextBuilder';

export type ModalContextType = {
  currentModal: string | number | null;
  sequence: Array<string | number>;
  modalData: null;
};

/**
 *  Default context values
 */
const CONTEXT: ModalContextType = {
  /**
   *  @property {Number|String|Null} CONTEXT.currentModal currently opened modal
   */
  currentModal: null,

  /**
   *  @property {Array.<Number|String>} CONTEXT.sequence sequence of modals (allows to go back)
   */
  sequence: [],

  /**
   *  @property {Any} CONTEXT.modalData current modal data (sent as payload when opening)
   */
  modalData: null,
};

/**
 *  React context for Modal
 */
const { Context, ContextProvider, useCustomContext } = contextBuilder(CONTEXT);

export const ModalContext = Context;
export const ModalContextProvider = ContextProvider;
export const useModalContext = useCustomContext;

export const useCloseModal = (_uid: string | number | null = null) => {
  const [currentModal, updateCurrentModal] = useModalContext('currentModal');
  const updateSequence = useModalContext('sequence')[1];

  return (uid?: string | number | null) => {
    const id = typeof uid === 'string' ? uid : _uid;

    if (id && currentModal !== id) {
      return;
    }
    updateCurrentModal(null);
    updateSequence([]);
  };
};

export const useOpenModal = (_uid: string | number | null = null) => {
  const [currentModal, updateCurrentModal] = useModalContext('currentModal');
  const [sequence, updateSequence] = useModalContext('sequence');
  const updateData = useModalContext('modalData')[1];

  return (uid: string | number, data: any = null) => {
    const id = typeof uid === 'string' ? uid : _uid;

    if (currentModal === id || id === null) {
      return;
    }

    updateData(data);
    updateCurrentModal(id);

    // Check if the modal ID is already in the sequence.
    // If it's not, add it to the end of the sequence.
    updateSequence(sequence.includes(id) ? sequence : [...sequence, id]);
  };
};

export const useModalBack = () => {
  const updateCurrentModal = useModalContext('currentModal')[1];
  const [sequence, updateSequence] = useModalContext('sequence');
  const updateData = useModalContext('modalData')[1];

  return (data: any = null) => {
    updateData(data);
    updateCurrentModal(sequence[sequence.length - 2]);

    // Remove the last modal ID from the sequence to go back to the previous modal.
    // Using slice to create a new array without the last element.
    updateSequence(sequence.slice(0, -1));
  };
};

export const useModalSequence = () => useModalContext('sequence')[0];
export const useCurrentModal = () => useModalContext('currentModal')[0];
export const useModalData = () => useModalContext('modalData');
