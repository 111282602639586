import { useRouter } from 'next/router';
import { createContext, useContext, ReactNode, useCallback, Dispatch, useState, SetStateAction } from 'react';
import { Configure, InstantSearch, useSearchBox, RefinementList, useRefinementList } from 'react-instantsearch';

import { UiState } from 'instantsearch.js';
import { useAlgolia } from '@/context/AlgoliaContext';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import { stateMapping } from '@/utilities/search/stateMapping';
import singletonRouter from 'next/router';
import qs from 'qs';

interface RefinementLists {
  [attribute: string]: string[];
}
type SearchContextType = {
  navigateToResults: (uiState: UiState) => Promise<boolean>;
  showDetails: boolean;
  setShowDetails: Dispatch<SetStateAction<boolean>>;
  searchInsideFolder: boolean | undefined;
  setSearchInsideFolder: Dispatch<SetStateAction<boolean | undefined>>;
};

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const VirtualSearchBox = (props: any) => {
  useSearchBox(); // This initializes the search box logic without the UI component
  return null;
};

// @ts-ignore
const VirtualRefinementList = ({ attribute }) => {
  useRefinementList({ attribute });
  return null;
};

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const [showDetails, setShowDetails] = useState(false);
  const [searchInsideFolder, setSearchInsideFolder] = useState<boolean | undefined>(false);

  const { algoliaBaseClient, algoliaIndexes } = useAlgolia();

  const navigateToResults = useCallback(
    (uiState: UiState) =>
      router.push({
        pathname: '/search',
        query: qs.stringify(stateMapping(algoliaIndexes.default).stateToRoute(uiState)),
      }),

    [algoliaIndexes, router.replace],
  );

  const filtersFromQuery = router.query.filters ? String(router.query.filters) : '';

  const refinementLists: RefinementLists = {};

  for (const key in router.query) {
    if (key.startsWith('refinementList')) {
      const attribute = key.match(/\[([^\]]+)\]/)?.[1];

      if (!attribute) continue;

      if (!refinementLists[attribute]) {
        refinementLists[attribute] = [];
      }

      const queryValue = router.query[key];

      if (Array.isArray(queryValue)) {
        queryValue.forEach((value) => refinementLists[attribute].push(value));
      } else if (typeof queryValue === 'string') {
        refinementLists[attribute].push(queryValue);
      }
    }
  }

  const isSearchPage = router.pathname === '/search';

  return (
    <SearchContext.Provider
      value={{
        navigateToResults,
        showDetails,
        setShowDetails,
        searchInsideFolder,
        setSearchInsideFolder,
      }}
    >
      <InstantSearch
        searchClient={algoliaBaseClient}
        indexName={algoliaIndexes.default}
        routing={
          isSearchPage
            ? {
                router: createInstantSearchRouterNext({
                  singletonRouter,
                  routerOptions: {
                    cleanUrlOnDispose: false,
                  },
                }),
                stateMapping: stateMapping(algoliaIndexes.default),
              }
            : undefined
        }
        future={{ preserveSharedStateOnUnmount: true }}
      >
        {/* Widgets are required for UI and Route change, do NOT remove */}
        <Configure attributesToRetrieve={['*']} filters={filtersFromQuery} />
        <VirtualSearchBox />
        {Object.keys(refinementLists).map((attribute, index) => (
          <VirtualRefinementList key={index} attribute={attribute} />
        ))}
        {children}
      </InstantSearch>
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
