import { useQuery } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getSelf, setLogoutToken } from '@/services/profile.service';

import { useAuth0 } from '@auth0/auth0-react';

export const useAccount = () => {
  const authGetProfileFn = useAuthenticatedQueryFn(getSelf);

  const { isAuthenticated } = useAuth0();

  const {
    data: profile,
    isLoading: profileLoading,
    refetch: refetchProfile,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: async (): Promise<any> => {
      const response = await authGetProfileFn({});

      const indexPrefix = response.search.index;

      const algoliaIndexes = {
        default: indexPrefix,
        created_asc: `${indexPrefix}-created-asc`,
        created_desc: `${indexPrefix}-created-desc`,
        published_asc: `${indexPrefix}-published-asc`,
        published_desc: `${indexPrefix}-published-desc`,
      };

      return {
        ...response,
        search: { ...response.search, algoliaIndexes },
      };
    },
    refetchOnMount: false,
    refetchInterval: false,
    staleTime: Infinity,
    enabled: isAuthenticated,
    retry: false,
  });

  return {
    profile,
    profileLoading,
    refetchProfile,
  };
};

export const useLogout = () => {
  const authSetLogoutTokenFn = useAuthenticatedQueryFn(setLogoutToken);

  const { logout } = useAuth0();

  const logoutUser = async () => {
    await authSetLogoutTokenFn({});
  };

  return {
    logoutUser,
  };
};
