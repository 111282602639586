import React, { useState, useContext, createContext, PropsWithChildren, FC } from 'react';
import { capitalize } from 'lodash';

type ContextObject = {
  [key: string]: any;
};

type UpdateFunction = (value: any) => void;
type ResetFunction = () => void;

type contextBuilderReturnType = {
  Context: React.Context<ContextObject>;
  ContextProvider: FC<PropsWithChildren & { value?: ContextObject }>;
  useCustomContext: (name: string) => [any, UpdateFunction, ResetFunction];
  useResetContext: () => () => void;
};

/**
 *  Helper function to build context
 *
 *  @param {Object} context Context object
 *  @param {Boolean} withState determines the use of 'useState' hook
 */
export const buildContext = (context: ContextObject, withState = true): ContextObject =>
  Object.keys(context).reduce((ctx: ContextObject, key: string) => {
    const capitalizedKey = capitalize(key);
    const [get, update]: [any, UpdateFunction] = withState ? useState(context[key]) : [context[key], () => {}];

    ctx[key] = get;
    ctx[`update${capitalizedKey}`] = update;
    ctx[`reset${capitalizedKey}`] = () => update(context[key]);
    return ctx;
  }, {});

/**
 *  Helper function to create 'Use context' hooks for each property
 *
 *  @param {Object} reactContext Context object
 *  @param {String} name context property (files, progress, error...)
 *
 *  @return {Array.<[
 *      Any,
 *      UpdateFunction,
 *      ResetFunction]>}  [{name}, update{Name}, reset{Name}]
 *
 */
export const createUseContextHooks =
  (reactContext: React.Context<ContextObject>) =>
  (name: string): [any, UpdateFunction, ResetFunction] => {
    const ctx = useContext(reactContext);
    const capitalizedKey = capitalize(name);

    return [ctx[name], ctx[`update${capitalizedKey}`], ctx[`reset${capitalizedKey}`]];
  };

/**
 *  Create Context reset function
 */
export const createUseResetContext =
  (reactContext: React.Context<ContextObject>, contextObject: ContextObject) => () => {
    const ctx = useContext(reactContext);

    return () => Object.keys(contextObject).map((name) => ctx[`reset${capitalize(name)}`]());
  };

/**
 *  Create Context provider
 */
export const createContextProvider = (
  reactContext: React.Context<ContextObject>,
  contextObject: ContextObject,
): FC<PropsWithChildren & { value?: ContextObject }> => {
  const ContextProvider: FC<PropsWithChildren & { value?: ContextObject }> = ({ children, value }) => {
    const ctx = buildContext(contextObject);

    return <reactContext.Provider value={{ ...ctx, ...value }}>{children}</reactContext.Provider>;
  };

  return ContextProvider;
};

export const contextBuilder = (contextObject: ContextObject): contextBuilderReturnType => {
  const Context = createContext(buildContext(contextObject, false));
  const ContextProvider = createContextProvider(Context, contextObject);
  const useCustomContext = createUseContextHooks(Context);
  const useResetContext = createUseResetContext(Context, contextObject);

  return {
    Context,
    ContextProvider,
    useCustomContext,
    useResetContext,
  };
};

export default contextBuilder;
