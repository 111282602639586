import { MouseEvent } from 'react';
import { assetSchema } from '@/types/asset';
import { Url } from 'next/dist/shared/lib/router/router';
import { z } from 'zod';

export enum GalleryView {
  LIST = 'list',
  TILE = 'tile',
}

export type PaginationHandler = {
  onClick?: () => void;
  href?: Url;
  disabled?: boolean;
};

export type Pagination = {
  onPageChange?: (page: number | undefined) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  handleNext?: PaginationHandler;
  handlePrev?: PaginationHandler;
  deselectAssetsOnPageChange?: boolean;
  onlyIcons?: boolean;
};
