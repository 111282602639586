import { damRequest as request, INTERNAL_V1 } from '@/hooks/damRequest';

/**
 * Sdk > User Profile Methods
 *
 */

/**
 * Gets the current user details
 * @returns {Object} Customer Profile
 */
export const getSelf = (params?: object, token?: string) => request('GET', `${INTERNAL_V1}/self`, token);

export const setLogoutToken = (params?: object, token?: string) => request('POST', `${INTERNAL_V1}/logout`, token);
