import { PropsWithChildren, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import { User } from '@/types/users';
import { Invitation } from '@/types/invitations';
import { Tag } from '@/types/tags';
import { MetadataField } from '@/types/metadata';

export type RowEntity = {
  id: string;
  getValue: (key: string) => any;
  original: User | Invitation | Tag | MetadataField;
};

type DataTableSelectionContextType = {
  selectedEntity: RowEntity | null;
  setSelectedEntity: Dispatch<SetStateAction<RowEntity | null>>;
  selectedEntityType: string | null;
  setSelectedEntityType: Dispatch<SetStateAction<string | null>>;
};

const DataTableSelectionContext = createContext<DataTableSelectionContextType | undefined>(undefined);

export function DataTableSelectionProvider({ children }: PropsWithChildren) {
  const [selectedEntity, setSelectedEntity] = useState<RowEntity | null>(null);
  const [selectedEntityType, setSelectedEntityType] = useState<string | null>(null);

  return (
    <DataTableSelectionContext.Provider
      value={{
        selectedEntity,
        setSelectedEntity,
        selectedEntityType,
        setSelectedEntityType,
      }}
    >
      {children}
    </DataTableSelectionContext.Provider>
  );
}

export function useDataTableSelection() {
  const context = useContext(DataTableSelectionContext);
  if (context === undefined) {
    throw new Error('useDataTableSelection must be used within an DataTableSelectionProvider');
  }
  return context;
}
