import { createContext, useContext, useState, useEffect, FC, PropsWithChildren } from 'react';
import { setGlobalErrorHandler } from '@/hooks/damRequest';

type GlobalErrorContextType = {
  errorCode: number | null;
};

const GlobalErrorContext = createContext<GlobalErrorContextType | undefined>(undefined);

export const GlobalErrorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [errorCode, setErrorCode] = useState<number | null>(null);

  useEffect(() => {
    setGlobalErrorHandler((code) => {
      setErrorCode(code);
    });

    return () => {
      setGlobalErrorHandler(null);
    };
  }, []);

  return <GlobalErrorContext.Provider value={{ errorCode }}>{children}</GlobalErrorContext.Provider>;
};

export const useGlobalError = () => {
  const context = useContext(GlobalErrorContext);
  if (!context) {
    throw new Error('useGlobalError must be used within a GlobalErrorProvider');
  }
  return context;
};
