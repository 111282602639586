import { z } from 'zod';

export enum NodeType {
  Assets = 'assets',
  Folders = 'folders',
  Albums = 'albums',
}

export const nodeSchema = z.object({
  id: z.string(),
  slug: z.string(),
  name: z.string(),
  locked: z.boolean().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
});
export type Node = z.infer<typeof nodeSchema>;

export const treeRootNodeSchema = z.object({
  id: z.string(),
  type: z.string(),
  shared: z.boolean().optional().nullable(),
  node: nodeSchema,
  with_children: z.boolean(),
});

export const trashRootNodeSchema = z.object({
  id: z.string(),
  type: z.string(),
  node: nodeSchema,
  variants: z
    .union([
      z.tuple([]),
      z.record(
        z.string(),
        z.object({
          width: z.number().optional(),
          height: z.number().optional(),
          url: z.string().optional(),
        }),
      ),
    ])
    .optional()
    .nullable(),
});
export type TreeRootNode = z.infer<typeof treeRootNodeSchema>;
export type TrashRootNode = z.infer<typeof trashRootNodeSchema>;

export type TreeRecursiveChildrenNode = z.infer<typeof treeRootNodeSchema> & {
  children?: Array<TreeRecursiveChildrenNode>;
};
const treeRecursiveChildrenNodeSchema: z.ZodType<TreeRecursiveChildrenNode> = treeRootNodeSchema.extend({
  children: z.lazy(() => treeRecursiveChildrenNodeSchema.array().optional()),
});

const treeBranchNodeSchema = treeRootNodeSchema.extend({
  children: treeRootNodeSchema.array(),
});
export type TreeBranchNode = z.infer<typeof treeBranchNodeSchema>;

export const getTreeRootNodeResponseSchema = z.array(treeRootNodeSchema);
export type GetTreeRootNodeResponse = z.infer<typeof getTreeRootNodeResponseSchema>;

export const getTreeBranchNodeResponseSchema = treeBranchNodeSchema;
export type GetTreeBranchNodeResponse = z.infer<typeof getTreeBranchNodeResponseSchema>;

export const moveNodesResponseSchema = z.object({
  moved: z.array(z.object({ parentFolderNodeId: z.string().optional(), nodes: z.array(z.string()) })),
});
export type MoveNodesResponse = z.infer<typeof moveNodesResponseSchema>;
