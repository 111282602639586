import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
  DragStartEvent,
  useDndMonitor,
  DragOverlay,
  closestCorners,
  pointerWithin,
} from '@dnd-kit/core';
import { DraggableItem } from '@/components/data-table/data-table-grid-list-view';
import { cn } from '@/lib/utils';

interface DragAndDropContextType {
  activeItem: any;
  updateActiveItem: Dispatch<SetStateAction<any>>;
  setModifiers: Dispatch<SetStateAction<Array<any>>>;
}

const DragAndDropContext = createContext<DragAndDropContextType | undefined>(undefined);

export const DragAndDropProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeItem, updateActiveItem] = useState<any>(null);
  const [modifiers, setModifiers] = useState<Array<any>>([]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DragAndDropContext.Provider value={{ activeItem, updateActiveItem, setModifiers }}>
      <DndContext
        collisionDetection={pointerWithin}
        modifiers={modifiers}
        onDragStart={({ active }) => updateActiveItem(active)}
        sensors={sensors}
      >
        {children}
        {/* <DragOverlay>
          {activeItem ? (
            <div className={cn('h-full p-3 transition-colors duration-150')}>{activeItem.data?.current.name}</div>
          ) : // <div>{activeItem.data?.current.name}</div>
          null}
        </DragOverlay> */}
      </DndContext>
    </DragAndDropContext.Provider>
  );
};

export const useDragAndDrop = () => {
  const context = useContext(DragAndDropContext);
  if (!context) {
    throw new Error('useDragAndDrop must be used within a DragAndDropProvider');
  }
  return context;
};
