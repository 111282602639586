const isLocalStorageAvailable = typeof localStorage !== 'undefined';

/**
 * Local storage keys
 * pattern: tags/{Name}/{Version}
 *
 * Change version when there's a change in the data structure
 */
export const LOCAL_STORAGE_KEYS = {
  assetSize: 'tags/assetSize/02-2024',
  showSidebar: 'tags/showSidebar/03-2024',
  showInspector: 'tags/showInspector/03-2024',
  galleryView: 'tags/galleryView/03-2024',
  assetsPerPage: 'tags/assetsPerPage/03-2024',
  visibleColumns: 'tags/visibleColumns/11-2024',
};

/**
 * safely fetch local storage (not fetching outside of browser)
 *
 * @param {String} key the local key to safely fetch the value for
 *  a local value if the key exists and local storage is available
 *
 * @returns {null | string} value of the key value pair if local storage exists
 */
const getLocalStorage = (key: string, parse: boolean = false): null | string => {
  if (isLocalStorageAvailable) {
    const value = localStorage.getItem(key);
    if (value === null) {
      return null;
    }
    return parse ? JSON.parse(value) : value;
  }
  return null;
};

/**
 * safely set local storage (not fetching outside of browser)
 *
 * @param {String} key The key for the key/value pair
 * @param {String} value the value to set for the key/value pair
 */
const setLocalStorage = (key: string, value: string) => {
  if (isLocalStorageAvailable) {
    localStorage.setItem(key, value);
  }
};

/**
 * safely remove item from local storage (not fetching outside of browser)
 *
 * @param {String} key the local key to be removed
 *
 */
const removeLocalStorage = (key: string) => {
  if (isLocalStorageAvailable) {
    return localStorage.removeItem(key);
  }
};

export { getLocalStorage, setLocalStorage, removeLocalStorage };
